import { useContext } from 'react';
import { Col, Row, Grid } from '@sumup/circuit-ui/legacy';

import CardSchemesContext from '~/shared/providers/CardSchemesContext';
import dataSelector from '~/shared/util/data-selector';
import CardSchemesCollection from '~/shared/components/CardSchemesCollection';

export const SCHEME_MAP: { [key: string]: boolean } = {
  alelo: true,
  amex: true,
  applePay: true,
  bancoEstado: true,
  boleto: true,
  dankort: true,
  diners: true,
  discover: true,
  ec: true,
  elo: true,
  eftpos: true,
  googlePay: true,
  hiper: true,
  hipercard: true,
  lgbt: true,
  maestro: true,
  mastercard: true,
  nfc: true,
  pix: true,
  postFinance: true,
  redCompra: true,
  samsungPay: true,
  sodexo: true,
  ticket: true,
  unionPay: true,
  visa: true,
  visaElectron: true,
  vpay: true,
  vr: true,
  sepa: true,
  fasterPayments: true,
  jcb: true,
  conecs: true,
  swile: true,
};

export type Props = {
  cardSchemes?: string[];
};

/**
 * A CardScheme component which displays the supported card types.
 */
function CardSchemes({ cardSchemes }: Props) {
  const cardSchemesFromContext = useContext(CardSchemesContext);
  const cardSchemesLocal = cardSchemes || cardSchemesFromContext || [];

  if (!cardSchemesLocal?.length) {
    return null;
  }

  const iconIds = cardSchemesLocal.filter(
    (scheme: string) => SCHEME_MAP[scheme],
  );

  return (
    <Grid
      data-selector={dataSelector('section', 'card_schemes')}
      data-elbcontext="component:card_schemes"
    >
      <Row>
        <Col
          span="12"
          data-selector={dataSelector('schemes_list', 'card_schemes')}
        >
          <CardSchemesCollection iconIds={iconIds} />
        </Col>
      </Row>
    </Grid>
  );
}
/**
 * @component
 */
export default CardSchemes;
