import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';

import PaymentMethodIcon from './components/PaymentMethodIcon';

import dataSelector from '~/shared/util/data-selector';

const iconStyles = ({ theme }: { theme?: Theme }) => css`
  box-sizing: content-box;
  display: inline-block;
  padding: ${theme.spacings.byte};
`;

const listWrapperBaseStyles = () => css`
  display: block;
  line-height: 0;
  text-align: center;
`;

const CardSchemeWrapper = styled('ul')(listWrapperBaseStyles);
const PaymentMethodIconWrap = styled('li')(iconStyles);

export type Props = {
  iconIds?: string[];
};

/**
 *   Displays a row of available or active card scheme icons
 */
const CardSchemesCollection = ({ iconIds = [] }: Props) => {
  if (!iconIds?.length) {
    return null;
  }

  return (
    <CardSchemeWrapper>
      {iconIds.map((iconId) => (
        <PaymentMethodIconWrap
          key={iconId}
          data-selector={dataSelector('schemes_list_item', 'card_schemes')}
        >
          <PaymentMethodIcon iconId={iconId} />
        </PaymentMethodIconWrap>
      ))}
    </CardSchemeWrapper>
  );
};

/**
 * @component
 */
export default CardSchemesCollection;
